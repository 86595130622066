<template>
  <v-row>
    <v-col cols="12">
      <v-card class="my-0">
        <v-card-title>
          {{ $t("Academic ranking") }}
        </v-card-title>
        <v-card-text>
          <v-sheet height="200px">
            <chart-line :styles="myStyles" :chart-data="chartDataTop" :options="options" />
          </v-sheet>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card class="my-0">
        <v-card-title>
          {{ $t("Income") }}
        </v-card-title>
        <v-card-text>
          <v-sheet height="200px">
            <chart-line :styles="myStyles" :chart-data="chartDataIncome" :options="optionsIncome" />
          </v-sheet>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card class="my-0">
        <v-card-title>
          {{ $t("Personal") }}
        </v-card-title>
        <v-card-text>
          <v-sheet height="200px">
            <chart-bar :styles="myStyles" :chart-data="chartData" :options="options" />
          </v-sheet>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import axios from "../../../plugins/axios"
import ChartBar from "../charts/Bar"
import ChartLine from "../charts/Line"
export default {
  name: "DashboardDashboard",
  components: { ChartBar, ChartLine },
  data() {
    return {
      path: "",
      records: [],
      loading: true,
      render: 1,
      chartData: {},
      chartDataTop: {},
      chartDataIncome: {},
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                stepSize: 1
              }
            }
          ]
        }
      },
      optionsIncome: {
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  computed: {
    myStyles() {
      return {
        height: "200px",
        position: "relative"
      }
    }
  },
  watch: {
    $route(to) {
      this.refreshPage()
    }
  },
  created() {
    //this.$log("routec ", this.$route)
    this.refreshPage()
  },
  methods: {
    refreshPage() {
      if (this.$route.name == "UniversityKPI") {
        this.getRecords()
      }
    },
    getRecords() {
      //this.$log("filtres ", this.filtersTM)
      this.records = []
      axios
        .get('infuni_university_kpi?max_results=1000&sort=[("year",1)]')
        .then(response => {
          //this.$log("vulnerability ", response)
          this.totalResults = response.data._meta.total
          this.records = response.data._items
          const colors = [
            "#42A5F5",
            "#66BB6A",
            "#EF5350",
            "#FFA726",
            "#7E57C2",
            "#26A69A",
            "#5C6BC0",
            "#9CCC65",
            "#1565C0",
            "#C62828",
            "#9E9D24",
            "#00695C",
            "#2E7D32",
            "#00838F",
            "#558B2F",
            "#0277BD",
            "#283593",
            "#4527A0",
            "#283593",
            "#EF6C00",
            "#37474F",
            "#4E342E"
          ]
          const labels = []
          const ds = { teaching: [], research: [], income: [], scimago: [], urap: [], times: [], qseeca: [] }
          this.records.map(itm => {
            labels.push(itm.year)
            ds.teaching.push(itm.personal.teaching_staff)
            ds.research.push(itm.personal.research_staff)
            ds.income.push(itm.income)
            ds.scimago.push(itm.academic_ranking.scimago)
            ds.urap.push(itm.academic_ranking.urap)
            ds.times.push(itm.academic_ranking.times)
            ds.qseeca.push(itm.academic_ranking.qseeca)
          })
          const datas = []
          let colorid = 0
          //this.$log("ds.urap ", ds.urap)
          datas.push({
            label: "Teaching staff",
            backgroundColor: colors[colorid++],
            borderWidth: 2,
            barThickness: 10,
            data: ds.teaching
          })
          datas.push({
            label: "Research staff",
            backgroundColor: colors[colorid++],
            borderWidth: 2,
            barThickness: 10,
            data: ds.research
          })
          this.chartData = {
            labels: labels,
            datasets: datas
          }
          this.chartDataIncome = {
            labels: labels,
            datasets: [
              {
                label: "Income",
                borderColor: colors[colorid],
                backgroundColor: colors[colorid++],
                fill: false,
                borderWidth: 2,
                barThickness: 10,
                data: ds.income
              }
            ]
          }
          colorid = 0
          this.chartDataTop = {
            labels: labels,
            datasets: [
              {
                label: "SCImago",
                borderColor: colors[colorid],
                backgroundColor: colors[colorid++],
                fill: false,
                borderWidth: 2,
                barThickness: 10,
                data: ds.scimago
              },
              {
                label: "URAP",
                borderColor: colors[colorid],
                backgroundColor: colors[colorid++],
                fill: false,
                borderWidth: 2,
                barThickness: 10,
                data: ds.urap
              },
              {
                label: "Times",
                borderColor: colors[colorid],
                backgroundColor: colors[colorid++],
                fill: false,
                borderWidth: 2,
                barThickness: 10,
                data: ds.times
              },
              {
                label: "QS EECA",
                borderColor: colors[colorid],
                backgroundColor: colors[colorid++],
                fill: false,
                borderWidth: 2,
                barThickness: 10,
                data: ds.qseeca
              }
            ]
          }
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },
    resolvePath(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    }
  }
}
</script>
<style scoped>
.v-data-table__expanded.v-data-table__expanded__content {
  box-shadow: none !important;
}
</style>
